import React, { useEffect, useState, useMemo, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import quotesImg from '../../../assests/quotesImg.webp'
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./index.css";
import { duration } from "moment";

const TestimonialSection = () => {
  const [testimonialSection, setTestimonialSection] = useState([]);
  const [testimonialContentSection, setTestimonialContentSection] = useState(
    []
  );
  useAOS();

  // useEffect(() => {
  //   // Fetch whatWeDoSection data from the backend when the component mounts
  //   axios
  //     .get(`${logigloUrl}/testimonialSection`)
  //     .then((response) => {
  //       console.log(response.data, "careersSection");
  //       setTestimonialSection(response.data.testimonialSection);
  //       setTestimonialContentSection(response.data.testimonialContentSection);
  //       // console.log(organizedArray);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching careersSection data:", error);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${logigloUrl}/testimonialSection`);
        setTestimonialSection(response.data.testimonialSection);
        setTestimonialContentSection(response.data.testimonialContentSection);
      } catch (error) {
        console.error("Error fetching testimonial data:", error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  // Memoize the fetched data to avoid fetching on every render
const memoizedTestimonialSection = useMemo(() => testimonialSection, [testimonialSection]);
const memoizedTestimonialContentSection = useMemo(() => testimonialContentSection, [testimonialContentSection]);


  // console.log(testimonialContentSection, "klklkl")

  const prevArrow = (
    <FaChevronLeft style={{ color: "black", fontSize: "24px" }} />
  );
  const nextArrow = (
    <FaChevronRight style={{ color: "black", fontSize: "24px" }} />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // width: "90vw",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed to 2 seconds
  };

  return (
    <div data-aos="fade-up" className="testimonial-sec-outer-container">
      {testimonialSection.map((section) => (
        <div key={section.id}>
          <div className="first-carousel-head-con">
            <div className="left-container">
              <p className="what-wedo-header">Testimonial</p>
            </div>
            <div className="right-container">
              {/* Any content you want for the right container */}
            </div>
          </div>
          <h3 className="testimonial-section-header-styles">
            {section.header}
          </h3>
          {/* <div style={{width: '75vw', backgroundColor : 'pink', display: 'flex', justifyContent: "center", alignItems: 'center'}}> */}
          <div className="slider-outer-con" >
            <Slider
              {...settings}
              className="your-slider-classname"
              prevArrow={<button className="slick-prev" />}
              nextArrow={
                <button
                  className="slick-next"
                  
                />
              }
            >
              {testimonialContentSection.map((contentItem, index) => (
                <div
                  key={contentItem.id}
                  className={`user-testimonial-container ${
                    index % 2 === 0 ? "even-user" : "odd-user"
                  }`}
                >
                  <div
                    className={`each-user-details-con ${
                      index % 2 === 0 ? "even-user" : "odd-user"
                    }`}
                  >
                    <div className="img-and-text-con">
                      <img src={contentItem.user_profile_img_url} className="user-img-size-styles" />
                      <div className="user-text-container">
                        <p className="user-name-styles">
                          {contentItem.user_name}
                        </p>
                        <p className="user-details-styles">
                          {contentItem.company_name}
                        </p>
                      </div>
                    </div>
                    <img
                      className="quote-icon"
                      src={
                        quotesImg
                      }
                    />
                  </div>
                  <p>{contentItem.description}</p>
                </div>
              ))}
            </Slider>
          </div>
          {/* </div> */}
        </div>
      ))}
    </div>
  );
};

export default TestimonialSection;
