import React, { useEffect, useState, useRef } from "react";
import truckImg from '../../../assests/truckImg.webp';
import logisticsImg from '../../../assests/logisticsImg.webp';
import educationImg from '../../../assests/educationImg.webp';
import eCommIng from '../../../assests/eCommIng.webp';
import cartImg from '../../../assests/cartImg.webp';
import eventImg from '../../../assests/eventImg.webp';
import discussionImg from '../../../assests/discussionImg.webp';
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";

import "./index.css";
import { duration } from "moment";

const LandingPageAnimation = () => {


  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  


  const images = [
    logisticsImg,
    truckImg,
    educationImg,
    eCommIng,
    cartImg,
    discussionImg,
    eventImg
  ];

  


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);
  






  return (
    <div className="animated-images-con" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
    <h1 className="text">Gateway of Logistics and Supply Chain World</h1>
    <p>Explore. Educate. Shop. Earn</p>
  </div>
  );
};

export default LandingPageAnimation;
